// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-thanks-index-js": () => import("./../src/pages/contact/thanks/index.js" /* webpackChunkName: "component---src-pages-contact-thanks-index-js" */),
  "component---src-pages-custom-order-thanks-index-js": () => import("./../src/pages/custom-order/thanks/index.js" /* webpackChunkName: "component---src-pages-custom-order-thanks-index-js" */),
  "component---src-pages-wholesale-thanks-index-js": () => import("./../src/pages/wholesale/thanks/index.js" /* webpackChunkName: "component---src-pages-wholesale-thanks-index-js" */),
  "component---src-templates-about-us-page-js": () => import("./../src/templates/about-us-page.js" /* webpackChunkName: "component---src-templates-about-us-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-custom-order-page-js": () => import("./../src/templates/custom-order-page.js" /* webpackChunkName: "component---src-templates-custom-order-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-wholesale-page-js": () => import("./../src/templates/wholesale-page.js" /* webpackChunkName: "component---src-templates-wholesale-page-js" */)
}

